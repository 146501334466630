import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Input } from '@angular/core';
import { StepComponent } from '../step/step.component';
import { Observable } from 'rxjs';

import { LanaService } from '../services/lana.service';

declare var LANA3D: any;
declare var VIEWER: any;

@Component({
  selector: 'app-lana',
  templateUrl: './lana.component.html',
  styleUrls: ['./lana.component.css'],
  encapsulation: ViewEncapsulation.Native
})
export class LanaComponent implements OnInit {

  editor: any;
  scene: any;
  mesh: any;
  //@Input() stepComponent: StepComponent;

  myModel$: Observable<string>;
  model: string = "";


  @ViewChild('lanaContainer') lanaContainer: ElementRef;

  constructor(private lanaService: LanaService) { }

  ngOnInit() {

    //this.clearScene();
    this.lanaService.getEditor().subscribe(editor => {
      if (editor) {
        this.editor = editor;
        this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
        this.editor.resize();
      }
    })
    /*this.editor = VIEWER.Editor.create();
    this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
    this.editor.resize();

    this.scene = LANA3D.NodeManager.createNode("scene");
    this.mesh = LANA3D.NodeManager.createNode("mesh");*/

    //this.editor.setScene(this.scene);

    /*this.editor = VIEWER.Editor.create();
    this.lanaContainer.nativeElement.appendChild(this.editor.getDomElement());
    this.editor.resize();
    this.scene = LANA3D.NodeManager.createNode("scene");
    this.editor.setScene(this.scene);
    LANA3D.AssetManager.loadBundle("models/test2.dae", result => {
      let o = result.scene;
      o.getLocalTransform().getPosition().add(new LANA3D.Vector3(20, 0, 0));
      this.scene.addChild(o);
    });*/

    /*this.lanaService.getModel().subscribe(model => {
      console.log("getModel: ", model);
      if (model) {
        LANA3D.AssetManager.loadBundle(model, result => {
          let o = result.scene;
          o.getLocalTransform().getPosition().add(new LANA3D.Vector3(20, 0, 0));
          this.scene.addChild(o);
        });
      }
    });*/

    /*this.lanaService.getScene().subscribe(scene => {
      console.log("He pedido la escena");
      if (scene) {
        console.log("setSecene");
        this.scene = scene;
        this.editor.setScene(this.scene);
      }

    });*/



    /*this.lanaService.getResetScene().subscribe(reset => {
      console.log("resetScene: ", reset);
      if (reset) {
        this.clearScene();
      }
    });*/



    //console.log("myModel:", this.stepComponent.model);
  }

  addModel(model) {
    console.log("loadModel:", model);
    LANA3D.AssetManager.loadBundle(model, result => {
      let o = result.scene;
      o.getLocalTransform().getPosition().add(new LANA3D.Vector3(20, 0, 0));
      this.scene.addChild(o);
    });
  }

  clearScene() {
    console.log("resetLanaScene");
    this.scene.clearSceneNode();
  }

}
