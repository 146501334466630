import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RouterModule, Routes } from '@angular/router';
import { routes } from './app.routes';

// PrimeNG Imports
import {
  ToolbarModule,
  ButtonModule,
  PanelModule,
  SplitButtonModule,
  FieldsetModule,
  InputTextareaModule,
  PaginatorModule,
  CheckboxModule,
  DataListModule,
  ConfirmDialogModule,
  ProgressSpinnerModule,
  DialogModule,
  ChipsModule,
  FileUploadModule,
  DataTableModule,
  GrowlModule,
  MessagesModule,
  MessageModule,
  CardModule,
} from 'primeng/primeng';

import {StepsModule} from 'primeng/steps';
import {AccordionModule} from 'primeng/accordion';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import {TabViewModule} from 'primeng/tabview';
import { ConfirmationService } from 'primeng/components/common/api';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FileSelectDirective } from 'ng2-file-upload';

import { AppComponent } from './app.component';
import { StepMenuComponent } from './step-menu/step-menu.component';
import { LanaComponent } from './lana/lana.component';

import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ExerciseComponent } from './exercise/exercise.component';
import { FsmComponent } from './fsm/fsm.component';
import { ExerciseInfoComponent } from './exercise-info/exercise-info.component';
import { ExerciseListComponent } from './exercise-list/exercise-list.component';
import { LoginComponent } from './login/login.component';
import { StepComponent } from './step/step.component';


// providers
import { AUTH_PROVIDERS } from './services/auth.service';
import { StoreService } from './services/store.service';
import { UserService } from './services/user.service';
import { SpinnerService } from './services/spinner.service';
import { MessageService } from './services/message.service';
import { ExerciseService } from './services/exercise.service';
import { StepService } from './services/step.service';
import { LanaService } from './services/lana.service';


@NgModule({
  declarations: [
    AppComponent,
    FileSelectDirective,
    StepMenuComponent,
    LanaComponent,
    ExerciseComponent,
    FsmComponent,
    ExerciseInfoComponent,
    ExerciseListComponent,
    LoginComponent,
    StepComponent
  ],
  imports: [
    AccordionModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToolbarModule,
    ButtonModule,
    HttpClientModule,
    HttpModule,
    PanelModule,
    SplitButtonModule,
    PaginatorModule,
    CheckboxModule,
    DataListModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    TabViewModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    ChipsModule,
    FileUploadModule,
    DataTableModule,
    ScrollPanelModule,
    ToastModule,
    GrowlModule,
    MessagesModule,
    MessageModule,
    CardModule,
    DataViewModule,
    StepsModule,
    RouterModule.forRoot(routes, {
      useHash: true
    }),
    InputTextareaModule
  ],
  providers: [
    AUTH_PROVIDERS,
    MessageService,
    StoreService,
    SpinnerService,
    UserService,
    ConfirmationService,
    ExerciseService,
    StepService,
    LanaService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
