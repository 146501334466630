import { ExerciseComponent } from './exercise/exercise.component';
import { ExerciseListComponent } from './exercise-list/exercise-list.component';
import { LoginComponent } from './login/login.component';

import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  //{ path: '', component: LoginComponent },
  //{ path: 'login', component: LoginComponent },
  { path: 'login/:idMoodle/:name/:username/:lang', component: LoginComponent },
  //{ path: 'login/:idMoodle', component: LoginComponent },
  { path: 'exerciseList', component: ExerciseListComponent },
  { path: 'exercise/:exerciseId', component: ExerciseComponent }
];
