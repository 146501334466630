import { Component, OnInit, Input } from '@angular/core';

import { StepService } from '../services/step.service';

import { ExerciseComponent } from '../exercise/exercise.component'
import { Router } from '@angular/router';

import { Step } from "../models/Step";

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
  @Input() step: any;
  model: string;

  constructor() { }

  ngOnInit() {
  }

  public onModel(myModel: string) {
    this.model = myModel;
    //console.log("myModel: ", this.model);
  }

}
