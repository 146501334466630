import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from "@angular/forms";

import { Teacher } from "../models/Teacher";
import { Exercise } from "../models/Exercise";

import { AuthService } from '../services/auth.service';
import { ExerciseService } from '../services/exercise.service';
import { SpinnerService } from "../services/spinner.service";

import { SelectItem } from 'primeng/primeng';

//import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-exercise-list',
  templateUrl: './exercise-list.component.html',
  styleUrls: ['./exercise-list.component.css']
  //providers: [MessageService]
})
export class ExerciseListComponent implements OnInit {
  names: string[];
  //idMoodle: string[];
  teacher: Teacher;
  myExercises: any;
  key: string;
  name: string;
  //myExercise: Exercise;

  sub: any;
  displayNewExerciseDialog: boolean = false;
  //exerciseform: FormGroup;
  exercise: Exercise = new Exercise();

  selectedOwner: string[] = ['Mine'];
  public: boolean = false;
  author: boolean = false;

  exerciseform: FormGroup;


  sortOptions: SelectItem[];
  sortKey: string;
  sortField: string;
  sortOrder: number;

  constructor(private authService: AuthService,
    private exerciseService: ExerciseService,
    private spinnerService: SpinnerService,
    private fb: FormBuilder,
    /*private messageService: MessageService*/) {
    this.names = [];
  }

  paginate(event) { }

  ngOnInit() {
    /*this.sub = this.route.params.subscribe(params => {
      this.idMoodle = params['id']; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
    });*/
    this.exerciseform = this.fb.group({
      'title': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'keyword': new FormControl([], Validators.required),
      'isPublic': new FormControl(false),
    });

    this.authService.getLogged().subscribe(
      teacher => {
        this.teacher = teacher;
        this.loadProjects();
      }
    );

  }

  showNewExerciseDialog() {
    this.exerciseform = this.fb.group({
      'title': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'keyword': new FormControl([], Validators.required),
      'isPublic': new FormControl(false),
    });
    this.displayNewExerciseDialog = true;
  }

  onSubmit() {
    let value = this.exerciseform.value;
    this.newExercise(value);
  }

  cancel() {
    this.displayNewExerciseDialog = false;
  }

  newExercise(value: any) {
    //console.log("valores de newExercise; ", value);
    this.spinnerService.displayLoader(true);
    let data: any = [];
    data.title = value.title;
    data.description = value.description;
    data.keyword = value.keyword;
    data.isPublic = value.isPublic;
    //console.log("valores de newExercise en data; ", value);
    //console.log("teacherId: ", this.teacher.id);
    this.exerciseService.newExercise(data, this.teacher.id);
    this.displayNewExerciseDialog = false;
    this.loadProjects();
    this.ngOnInit();
    //window.location.reload();
  }

  findByKeywordName(key: string, name: string) {
    //console.log("buscar por clave: ", key);
    this.myExercises = [];
    if (key && !name) {
      this.exerciseService.findByKeyword(key).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
    else if (!key && name) {
      this.exerciseService.findByName(name).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
    else {
      this.exerciseService.findByKeywordName(key, name).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
    /*this.key = "";
    this.name = "";*/
  }


  loadProjects() {
    //search projects where teacher.id is editor
    this.myExercises = [];
    if (this.teacher) {
      this.exerciseService.getAllFromAuthor(this.teacher.id).subscribe(
        exercises => {
          this.myExercises = exercises;
        });
    }
  }

  searchPublic() {
    //console.log("On change value; ", this.public);
    this.myExercises = [];
    //if (this.public) {
    //search all public exercises
    this.exerciseService.getAllPublicExercises().subscribe(
      exercises => {
        console.log("allPublicExercises: ", this.myExercises);
        this.myExercises = exercises;
      });
    //}
  }
}
