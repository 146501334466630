import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SpinnerService } from '../services/spinner.service';
import { MessageService } from '../services/message.service';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
//import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { Teacher } from '../models/Teacher';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private START_ROUTE = 'main';
  idMoodle: string;
  name: string;
  username: string;
  lang: string;
  sub: any;
  address: string;
  teacher: any;

  constructor(private route: ActivatedRoute,
    public authService: AuthService,
    //private fb: FormBuilder,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private userService: UserService,
    //private translate: TranslateService,
    private router: Router
  ) {
    this.teacher = new Teacher;
    this.route.params.subscribe(params => {
      this.idMoodle = params['idMoodle'];
      this.name = params['name'];
      this.username = params['username'];
      this.lang = params['lang'];

      console.log(params);

      //console.log(this.idMoodle);
      console.log("this.route: ", this.route);
      console.log("params idMoodle: ", this.idMoodle);
      console.log("name: ", this.username);
      console.log("lang: ", this.lang);
      this.login(this.idMoodle, this.name, this.username);
    })

  }

  ngOnInit() {

  }

  login(idMoodle: string, name: string, username: string): void {
    //console.log('login');
    this.spinnerService.displayLoader(true);

    this.authService.login(idMoodle, name, username).subscribe(
      teacher => {
        //console.log("teacher", teacher);
        if (teacher.id) {
          //console.log("teacher.id", teacher.id);
          this.messageService.sendMessage({
            severity: 'success',
            summary: 'Welcome',
            detail: teacher.id
          });
          this.START_ROUTE = 'exerciseList';
          this.router.navigate([this.START_ROUTE]);
          this.authService.editTeacher(teacher.id, teacher.idMoodle, name, username);

        } else {
          //console.log("no teacher id");
          this.messageService.sendMessage({
            severity: 'error',
            summary: 'Check credential',
            detail: 'Check your moodleId'
          });
        }
        this.spinnerService.displayLoader(false);
      },
      error => {
        //first login of this teacher -> create the user
        console.log("Error: ", error);

        //this.teacher.idMoodle = idMoodle;
        //this.userService.create(this.teacher);
        /*if (error.status === '401') {
          this.messageService.sendMessage({
            severity: 'error',
            summary: 'Login error',
            detail: 'Check email and password'
          });
        } else {
          this.messageService.sendMessage({
            severity: 'error',
            summary: 'Login error',
            detail: 'Retry or contact the service provider'
          });
        }*/

        this.spinnerService.displayLoader(false);
      }
    );
  }

}
