import { Component, OnInit, Input } from '@angular/core';

import { Step } from "../models/Step";
import { Teacher } from "../models/Teacher";
import { Exercise } from "../models/Exercise";
import { MenuItem } from 'primeng/api';
import { AuthService } from '../services/auth.service';
import { StepService } from '../services/step.service';
import { ExerciseService } from '../services/exercise.service';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.css']
})
export class ExerciseComponent implements OnInit {

  //@Input() exercise: Exercise;

  exercise: Exercise;
  teacher: Teacher;
  nSteps: any;
  mySteps: any;
  exerciseId: string;
  myStep: any;
stepsMenu: MenuItem[];

  activeIndex: number = 0;
  step: Step = new Step();

  constructor(private authService: AuthService,
    private stepService: StepService,
    private exerciseService: ExerciseService,
    private router: Router,
    private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      this.exerciseId = params['exerciseId'];
      //var name = params['name']

      let newStep: any = [];
          newStep.title = "Insert title";
          newStep.description = "Insert description";
          newStep.help = "Insert help";
          newStep.initialModels = [];
          newStep.correctModels = [];
          newStep.incorrectModels = [];
          newStep.order = 0;
          newStep.exerciseId = "this.exerciseId";

      this.myStep = newStep;
    })

  }

  ngOnInit() {
    //ToDo: read the steps of the exercise
    //this.nSteps = 0;
    this.myStep = undefined;
    this.authService.getLogged().subscribe(
      teacher => {
        this.teacher = teacher;
      }
    );
    //console.log("El ejercicio: ", this.exerciseId);
    this.exerciseService.getByExerciseId(this.exerciseId).subscribe(
      exercise => {
        //console.log("me devuelve el ejercicio: ", exercise);
        this.exercise = exercise;
        this.getSteps(this.exerciseId);
        //this.myStep = this.mySteps[0];
      }
    );

  }


  getSteps(exerciseId) {
    //search projects where teacher.id is editor
    this.mySteps = [];
    let myOptions: any[] = [];
    this.stepService.getAllFromExercise(exerciseId).subscribe(
      steps => {
        this.mySteps = steps;
        //console.log(this.mySteps);
        this.nSteps = this.mySteps.length;

        if (this.nSteps === 0) {
          let newStep: any = [];
          newStep.title = "Insert title";
          newStep.description = "Insert description";
          newStep.help = "Insert help";
          newStep.initialModels = [];
          newStep.correctModels = [];
          newStep.incorrectModels = [];
          newStep.order = 0;
          newStep.exerciseId = this.exerciseId;


          this.stepService.newStep(newStep).subscribe(step => {
            this.mySteps.push(step);
          })
        }

        for (var i = 0; i < this.mySteps.length; i++) {
          myOptions[i] = {
            command: (event: any) => {
              this.activeIndex = event.index;
              this.myStep = this.mySteps[event.index];
              this.ngOnInit();
            }
          };
        }
        this.stepsMenu = myOptions;
        this.myStep = this.mySteps[this.activeIndex];

      });
  }

  createStep() {
    this.nSteps = this.mySteps.length;
    let newStep: any = [];
    newStep.title = "Insert title";
    newStep.description = "Insert description";
    newStep.help = "Insert help";
    //get last last step models
    let lastStep = this.mySteps[this.nSteps - 1];
    console.log("lastStep: ", lastStep);
    let previousModels: any[] = [];
    for (let model of lastStep.initialModels) {
      previousModels.push(model);
    }
    for (let model of lastStep.correctModels) {
      previousModels.push(model);
    }
    //previousModels.push(lastStep.initialModels)
    //previousModels.push(lastStep.correctModels);
    console.log("*********************************** prev: ", previousModels);
    newStep.initialModels = previousModels;
    newStep.correctModels = [];
    newStep.incorrectModels = [];
    newStep.exerciseId = this.exerciseId;

    newStep.order = this.nSteps;
    //this.nSteps = this.nSteps + 1; //add step

    this.stepService.newStep(newStep).subscribe(step => {
      this.mySteps.push(step);
    })

    //this.stepService.newStep(newStep);

    //this.mySteps.push(newStep);
    //console.log("Los pasos que tiene el ejercicio: ", this.mySteps);

    //actualiza tabla de pasos
    this.ngOnInit();

    /*this.stepService.newStep(newStep, this.exerciseId).subscribe(step => {
      this.mySteps.push(step);
    })*/
  }

  /*save() {
    console.log("guardar los cambios realizados:", this.mySteps);
  }*/
}
