import { Injectable } from "@angular/core";
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../environments/environment";
import { Exercise } from "../models/Exercise";
import { RestService } from "./rest.service";

import { StoreService } from './store.service';

@Injectable()
export class ExerciseService {
  private BASE_URL: string = environment.apiEndpoint + '/api/exercises/';
  private exercise: BehaviorSubject<Exercise> = new BehaviorSubject<Exercise>(null);
  private exercise$: Observable<Exercise> = this.exercise.asObservable();

  constructor(private http: Http) {
  }

  getByExerciseId(exerciseId: string) {
    //console.log("get ByexerciseId");
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "id" + '\u0022' + ":" + '\u0022' + exerciseId + '\u0022' + "}}";
    //console.log(query);
    //console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  getAllFromEditors(teacherId: string) {
    let exercises = [];
    //teacherId = "5b4c7f5ba7494c0014543497";
    //console.log("get exercises");
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "editors" + '\u0022' + ":" + '\u0022' + teacherId + '\u0022' + "}}";
    //console.log(query);
    //console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          //console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  getAllFromAuthor(teacherId: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "authorId" + '\u0022' + ":" + '\u0022' + teacherId + '\u0022' + "}}";
    //console.log(query);
    //console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  getAllPublicExercises() {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "isPublic" + '\u0022' + ":" + '\u0022' + "true" + '\u0022' + "}}";
    //console.log(query);
    //console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  findByKeyword(keyword: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "keyword" + '\u0022' + ":" + '\u0022' + keyword + '\u0022' + "}}";
    //console.log(query);
    //console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  findByName(name: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "author" + '\u0022' + ":" + '\u0022' + name + '\u0022' + "}}";
    //console.log(query);
    //console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  findByKeywordName(keyword: string, name: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "author" + '\u0022' + ":" + '\u0022' + name + '\u0022' + "}}";
    //console.log(query);
    //console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  deleteExercise(exerciseId: string) {
    //console.log("delete exercise: ", exerciseId);
    return this.http
      .delete(
        this.BASE_URL + exerciseId
      ).toPromise()
      .then(res => {
        console.log("res: ", res);
        <any[]>res.json().data
      })
      .then(data => {
        console.log("return data: ", data);
        return data;
      });
  }

  newExercise(resource: any, ownerId: string) {
    const newService = 'newExercise';
    let created = new Date();
    let modified = new Date();
    return this.http
      .post(
        this.BASE_URL + 'newExercise',
        {
          title: resource.title,
          description: resource.description,
          keyword: resource.keyword,
          isPublic: resource.isPublic,
          authorId: ownerId,
          ownerId: ownerId,
          editors: [ownerId],
          created: created,
          modified: modified
        }
      ).toPromise()
      .then(res => {
        //console.log("res: ", res);
        <any[]>res.json().data
      })
      .then(data => {
        //console.log("return data: ", data);
        return data;
      });
  }

  editExercise(resource: any) {
    const newService = 'newExercise';
    //console.log("Resource editExercise: ", resource);
    let modified = new Date();
    return this.http
      .put(
        this.BASE_URL,
        {
          title: resource.title,
          description: resource.description,
          keyword: resource.keyword,
          isPublic: resource.isPublic,
          authorId: resource.authorId,
          ownerId: resource.ownerId,
          editors: resource.editors,
          created: resource.created,
          id: resource.id,
          modified: modified
        }
      ).toPromise()
      .then(res => {
        //console.log("res: ", res);
        <any[]>res.json().data
      })
      .then(data => {
        //console.log("return data: ", data);
        return data;
      });
  }

}
