import { Injectable } from "@angular/core";
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { catchError } from "rxjs/operators/catchError";
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../environments/environment";
import { Step } from "../models/Step";
import { RestService } from "./rest.service";

import { StoreService } from './store.service';

@Injectable()
export class StepService {
  private BASE_URL: string = environment.apiEndpoint + '/api/steps/';
  private step: BehaviorSubject<Step> = new BehaviorSubject<Step>(null);
  private step$: Observable<Step> = this.step.asObservable();

  constructor(private http: Http) {
  }

  /*getAllFromEditors(teacherId: string) {
    let steps = [];
    //teacherId = "5b4c7f5ba7494c0014543497";
    console.log("get steps");
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "editors" + '\u0022' + ":" + '\u0022' + teacherId + '\u0022' + "}}";
    console.log(query);
    console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }

  getAllFromAuthor(teacherId: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "authorId" + '\u0022' + ":" + '\u0022' + teacherId + '\u0022' + "}}";
    console.log(query);
    console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }*/

  /*getAllPublicExercises() {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "isPublic" + '\u0022' + ":" + '\u0022' + "true" + '\u0022' + "}}";
    console.log(query);
    console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }*/

  getAllFromExercise(exerciseId: string) {
    let query = "?filter={" + '\u0022' + "where" + '\u0022' + ":{" + '\u0022' + "exerciseId" + '\u0022' + ":" + '\u0022' + exerciseId + '\u0022' + "}}";
    //let query = "?filter={" + '\u0022' + "exerciseId" + '\u0022' + ":" + '\u0022' + exerciseId + '\u0022' + "}";
    console.log(query);
    console.log(this.BASE_URL + query)
    return this.http.get(this.BASE_URL + query, { withCredentials: true })
      .pipe(
        map((res: Response) => {
          console.log('response getAllFromExercise; ', res);
          return res.json();
        }),
        catchError((error: Response) => {
          console.log(error);
          return Observable.throw(error.json().error || "Server error");
        })
      );
  }


  deleteStep(stepId: string) {
    console.log("delete step: ", stepId);
    return this.http
      .delete(
        this.BASE_URL + stepId
      ).toPromise()
      .then(res => {
        console.log("res: ", res);
        <any[]>res.json().data
      })
      .then(data => {
        console.log("return data: ", data);
        return data;
      });
  }

  renumberSteps(exerciseId: string, order: number) {
    //renumber the next steps:
    //var step;
    this.getAllFromExercise(exerciseId).subscribe(
      steps => {
        console.log("Los pasos del ejercicio que quedan: renumerar:", steps);
        for (var i = 0; i < steps.length; i++) {
          var step = steps[i];
          if (step.order > order) {
            console.log("reedito: ", step);
            step.order = step.order - 1;
            this.editStep(step);
          }

        }
      });

  }

  /*newStep(resource: any) {
    const newService = 'newStep';
    console.log("el paso que voy a crear: ", resource);
    return this.http
      .post(
        this.BASE_URL + 'newStep',
        {
          title: resource.title,
          description: resource.description,
          help: resource.help,
          order: resource.order,
          initialModels: resource.initialModels,
          correctModels: resource.correctModels,
          incorrectModels: resource.incorrectModels,
          exerciseId: resource.exerciseId,
        }
      ).toPromise()
      .then(res => {
        console.log("res step: ", res);
        <any[]>res.json().data
      })
      .then(data => {
        console.log("return data step: ", data);
        return data;
      });
  }*/

  newStep(resource: any) {
    const newService = 'newStep';
    console.log("el paso que voy a crear: ", resource);
    return this.http
      .post(
        this.BASE_URL + 'newStep',
        {
          title: resource.title,
          description: resource.description,
          help: resource.help,
          order: resource.order,
          initialModels: resource.initialModels,
          correctModels: resource.correctModels,
          incorrectModels: resource.incorrectModels,
          exerciseId: resource.exerciseId,
        }
      ).pipe(
        map((response: Response) => {
          // login successful if there's a accessToken token in the response
          let step = response.json();
          //console.log("teacher:");
          //console.log(teacher);

          return step;
          //return teacher;
        })
      );
  }

  /*editStep(resource: any) {
    console.log("Resource editExercise: ", resource);
    return this.http
      .put(
        this.BASE_URL,
        {
          title: resource.title,
          description: resource.description,
          help: resource.help,
          order: resource.order,
          id: resource.id,
          initialModels: resource.initialModels,
          correctModels: resource.correctModels,
          incorrectModels: resource.incorrectModels,
          exerciseId: resource.exerciseId
        }
      ).pipe(
        map((response: Response) => {
          // login successful if there's a accessToken token in the response
          let step = response.json();
          console.log("step después de editar:");
          console.log(step);

          return step;
          //return teacher;
        })
      );
  }*/

  editStep(resource: any) {
    console.log("Resource initialModels: ", resource.initialModels);
    return this.http
      .put(
        this.BASE_URL,
        {
          title: resource.title,
          description: resource.description,
          help: resource.help,
          order: resource.order,
          id: resource.id,
          initialModels: resource.initialModels,
          correctModels: resource.correctModels,
          incorrectModels: resource.incorrectModels,
          exerciseId: resource.exerciseId
        }
      ).toPromise()
      .then(res => {
        //console.log("res: ", res);
        <any[]>res.json().data
      })
      .then(data => {
        //console.log("return data: ", data);
        return data;
      });
  }

}
