import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
// import { Category } from '../models/Category';
import { RestService } from './rest.service';
import { map, tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { Teacher, emptyTeacher } from '../models/Teacher';

declare var LANA3D: any;
declare var VIEWER: any;

@Injectable()
export class LanaService {
  editor: any;
  scene: any;

  private myModel: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private myModel$: Observable<string> = this.myModel.asObservable();
  private clearScene: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private clearScene$: Observable<boolean> = this.clearScene.asObservable();

  private myScene: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private myScene$: Observable<any> = this.myScene.asObservable();

  private myEditor: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private myEditor$: Observable<any> = this.myScene.asObservable();


  constructor() {
    this.editor = VIEWER.Editor.create();
    this.scene = LANA3D.NodeManager.createNode("scene");
    this.editor.setScene(this.scene);

  }

  /*addModel(model: string) {
    console.log("lanaService:", model);
    this.myModel.next(model);

  }*/

  addModel(model: string) {
    console.log("addModel: ", model);
    LANA3D.AssetManager.loadBundle(model, result => {
      let o = result.scene;
      o.getLocalTransform().getPosition().add(new LANA3D.Vector3(0, 0, 0));
      this.scene.addChild(o);
    });
    //this.myScene.next(this.scene);
    this.myEditor.next(this.editor);
  }

  getModel() {
    return this.myModel.asObservable();
  }

  resetScene() {
    console.log("lanaService: clear scene ");
    this.editor.deleteScene();
    this.myEditor.next(this.editor);
  }

  getResetScene() {
    return this.clearScene.asObservable();
  }

  getScene() {
    return this.myScene.asObservable();
  }

  getEditor() {
    return this.myEditor.asObservable();
  }


  /*sendMessage(message: Message): void {
    this.subject.next(message);
  }

  getMessage(): Observable<Message> {
    return this.subject.asObservable();
  }*/
}
