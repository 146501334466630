import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, Response } from '@angular/http';
import { MessageService } from '../services/message.service';
import { StepComponent } from '../step/step.component';

import { StepService } from '../services/step.service';
import { LanaService } from '../services/lana.service';
import { Step } from '../models/Step';
import { environment } from "./../../environments/environment";

import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';

import { ConfirmationService, TTResizableColumn } from "primeng/primeng";

import { Message } from 'primeng/api';
//import { $ } from 'protractor';
import * as $ from 'jquery';

import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';
import { all } from 'q';
import { text } from '@angular/core/src/render3/instructions';
//import { runInThisContext } from 'vm';

const URL = 'http://localhost:3000/api/upload';

@Component({
  selector: 'app-step-menu',
  templateUrl: './step-menu.component.html',
  styleUrls: ['./step-menu.component.css']
})
export class StepMenuComponent implements OnInit {

  @Input() stepComponent: StepComponent;

  @Output() public model = new EventEmitter<string>();

  environment: any = environment;

  description: string;
  title: string;
  help: string;
  order: number;
  id: string;
  addTexture: boolean = false;
  addModel: boolean = false;
  daeModel: string;

  selectedFile: string;
  //uploadedFiles: any[] = [];

  initialModels: any[];// = [];
  correctModels: any[];// = [];
  incorrectModels: any[];// = [];

  uploadedFileInfo: any;
  uploadedDAE: any;
  textureList: any[] = [];
  texture: string = "";

  showModel: boolean = true;


  //correctModelsSubscription$: Observable<any>;

  exerciseId: string;

  msgs: Message[] = [];

  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo' });

  constructor(private http: Http,
    private messageService: MessageService,
    public stepService: StepService,
    private lanaService: LanaService,
    private router: Router,
    private confirmationService: ConfirmationService,
  ) { }

  connectCorrectModels(data) {
    this.correctModels
  }

  ngOnInit() {
    //get step data
    this.title = this.stepComponent.step.title;
    this.description = this.stepComponent.step.description;
    this.exerciseId = this.stepComponent.step.exerciseId;
    this.help = this.stepComponent.step.help;
    this.order = this.stepComponent.step.order;
    this.id = this.stepComponent.step.id;
    //console.log("***** step id: ", this.id);
    //console.log("***** exerciseId: ", this.exerciseId);
    this.initialModels = this.stepComponent.step.initialModels;
    this.correctModels = this.stepComponent.step.correctModels;
    this.incorrectModels = this.stepComponent.step.incorrectModels;

    this.loadModels();

    //this.correctModelsSubscription$ = new Observable(observer => this.handleCorrectFileSelect(Event));
    //this.initialModels = [];
    //this.correctModels = ["correcto"];

  }


  onCorrectUpload(event) {
    this.uploadedFileInfo = JSON.parse(event.xhr.response);
    this.showModel = true;
    /*this.messageService.sendMessage({
      severity: "info",
      summary: this.translate.instant('Warning_Save_File_Title'),
      detail: this.translate.instant('Warning_Save_File', { value: uploadedFileInfo.name }),
    });*/

    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.correctModels.push(this.uploadedFileInfo);
    let selected = this.uploadedFileInfo.name;
    //this.lanaService.addModel(uploadedFileInfo.url);
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    console.log("correct daeModel: ", this.daeModel);
    this.addModel = true;
  }

  onIncorrectUpload(event) {
    this.uploadedFileInfo = JSON.parse(event.xhr.response);
    /*this.messageService.sendMessage({
      severity: "info",
      summary: this.translate.instant('Warning_Save_File_Title'),
      detail: this.translate.instant('Warning_Save_File', { value: uploadedFileInfo.name }),
    });*/
    this.showModel = false;
    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.incorrectModels.push(this.uploadedFileInfo);
    let selected = this.uploadedFileInfo.name;
    //this.lanaService.addModel(uploadedFileInfo.url);
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    console.log("correct daeModel: ", this.daeModel);
    this.addModel = true;

  }

  onInitialUpload(event) {
    this.uploadedFileInfo = JSON.parse(event.xhr.response);
    this.showModel = true;
    /*this.messageService.sendMessage({
      severity: "info",
      summary: this.translate.instant('Warning_Save_File_Title'),
      detail: this.translate.instant('Warning_Save_File', { value: uploadedFileInfo.name }),
    });*/

    this.uploadedFileInfo.url = environment.apiEndpoint + this.uploadedFileInfo.url;
    this.initialModels.push(this.uploadedFileInfo);

    //console.log("uploadedFile", uploadedFileInfo);


    //this.showAddTexture();
    let selected = this.uploadedFileInfo.name;
    console.log("selected: ", selected);
    this.daeModel = selected.substring(0, selected.indexOf(".")) + ".dae";
    this.addModel = true;
    //this.addTexture = true;
    //readTextureInfo


    //send model
    //this.lanaService.addModel(uploadedFileInfo.url);
    //this.model.emit(uploadedFileInfo.url);
  }

  onUploadDAE(event) {
    this.addModel = false;
    this.uploadedDAE = JSON.parse(event.xhr.response);
    this.uploadedDAE.url = environment.apiEndpoint + this.uploadedDAE.url;
    console.log("dae.url: ", this.uploadedDAE.url);
    //this.initialModels.push(this.uploadedFileInfo);

    var mydaeObj = this.getDAEInfo(this.uploadedDAE.url);

    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(mydaeObj, "text/xml");
    var library_images = xmlDoc.getElementsByTagName("library_images")[0];
    if (library_images !== undefined) {
      var x = library_images.getElementsByTagName("image")[0];
      for (var i = 0; i < library_images.childElementCount; i++) {
        var image = library_images.getElementsByTagName("init_from")[i];
        let img = image.childNodes[0].nodeValue;
        this.textureList[i] = img.substring(img.indexOf('/') + 1);
      }
    }
    //var image = x.getElementsByTagName("init_from")[0];
    /*for (let i of image.childNodes) {
      this.textureList[i] = image.childNodes[i].nodeValue;
    }*/
    //this.texture = image.childNodes[0].nodeValue;
    //this.texture = this.texture.substring(this.texture.indexOf('/') + 1);
    if (this.textureList.length > 0) {
      this.addTexture = true;
    }
    else {
      this.lanaService.addModel(this.uploadedDAE.url);
    }
  }

  onInitialSelect(event) {
    console.log("onInitialSelect: ", event);
    for (let file in event.files) {
      console.log("Ha seleccionado: ", file);
    }
  }

  onUploadTexture(event, i) {
    console.log("la textura que tengo que cargar: ", i);
    console.log("textureList: ", this.textureList);
    var index = this.textureList.indexOf(i);
    if (index > -1) {
      this.textureList.splice(index, 1);
      console.log("textureList 2: ", this.textureList);
    }

    if (this.textureList.length <= 0) {
      this.addTexture = false;
    }
    if (this.showModel) {
      console.log("this.lanaService.addModel");
      this.lanaService.addModel(this.uploadedDAE.url);
    }
    this.texture = "";
  }

  showAddTexture(texture: string) {
    this.addTexture = true;
    console.log("La textura que tengo que pedir: ", texture);
  }



  cancelAddTexture() {
    this.addTexture = false;
  }

  cancelAddDAE() {
    this.addModel = false;
  }

  hasInitialModels() {
    if (this.initialModels.length > 0) {
      return true;
    }
  }

  hasCorrectModels() {
    if (this.correctModels.length > 0) {
      //console.log("hasCorrectModels");
      return true;
    }
  }

  hasIncorrectModels() {
    if (this.incorrectModels.length > 0) {
      return true;
    }
  }

  save() {
    //console.log("this.correctModelsSub;", this.correctModelsSubscription$);
    let newStep: Step;
    newStep = this.stepComponent.step;
    newStep.title = this.title;
    newStep.description = this.description;
    newStep.exerciseId = this.exerciseId;
    newStep.help = this.help;
    newStep.initialModels = this.initialModels;
    newStep.correctModels = this.correctModels;
    newStep.incorrectModels = this.incorrectModels;
    newStep.id = this.id;
    console.log("salvar paso: ", newStep);
    this.stepService.editStep(newStep);

    console.log("modelos; ", this.initialModels);

    let summary = "Saved";
    let detail = "The step was correctly saved";
    this.showInfo(summary, detail);
  }

  confirmDelete() {
    //console.log("this.correctModels: ", this.correctModels);
    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        //Actual logic to perform a confirmation
        this.delete();
      }
    });
  }

  delete() {
    let stepId = this.stepComponent.step.id;
    let stepOrder = this.stepComponent.step.order;
    //console.log("eliminar paso: ", stepId);
    this.stepService.deleteStep(stepId);

    //TODO: Modificar orden de los pasos posteriores:
    this.stepService.renumberSteps(this.exerciseId, this.order);
    //console.log("recargo los pasos del ejercicio: ", this.exerciseId);
    //this.router.navigate(["exercise", this.exerciseId]);

    //this.ngOnInit();
    let summary = "Deleted";
    let detail = "The step was correctly deleted";
    this.showInfo(summary, detail);
  }

  showInfo(summary: string, detail: string) {
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: summary, detail: detail });
  }


  deleteInitialModel(model) {
    console.log("deleting: ", model);
    var index = this.initialModels.indexOf(model);
    if (index > -1) {
      this.initialModels.splice(index, 1);
    }
  }

  deleteCorrectModel(model) {
    console.log("deleting: ", model);
    var index = this.correctModels.indexOf(model);
    if (index > -1) {
      this.correctModels.splice(index, 1);
    }
  }

  deleteIncorrectModel(model) {
    console.log("deleting: ", model);
    var index = this.incorrectModels.indexOf(model);
    if (index > -1) {
      this.incorrectModels.splice(index, 1);
    }
  }

  getJson(url) {
    return JSON.parse($.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      global: false,
      async: false,
      success: function (data) {
        return data;
      }
    }).responseText);
  }

  getDAEInfo(url) {
    return $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      global: false,
      async: false,
      success: function (data) {
        return data;
      }
    }).responseText;
  }

  loadModels() {
    //resetLana
    //this.lanaService.resetScene();
    console.log("cargar los modelos iniciales y finales en la escena");

    console.log("this.initialModels:", this.initialModels);
    if (this.initialModels.length > 0) {
      for (let initialModel of this.initialModels) {
        console.log("load: ", initialModel);
        let mydaeObj = initialModel.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        console.log(mydaeObj);
        this.lanaService.addModel(mydaeObj);
      }
    }
    console.log("CorrectModels:", this.correctModels);
    if (this.correctModels.length > 0) {
      for (let correctModel of this.correctModels) {
        console.log("load: ", correctModel);
        let mydaeObj = correctModel.url;
        mydaeObj = mydaeObj.substring(0, mydaeObj.indexOf(".viwork")) + ".dae";
        console.log(mydaeObj);
        this.lanaService.addModel(mydaeObj);
      }
    }
  }

}
