import { Injectable } from '@angular/core';
import { Message } from 'primeng/components/common/api';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class MessageService {
  msgs: Message[] = [];
  private MSG_SHOW_MS: Number = 5000;
  private subject: Subject<Message> = new Subject<Message>();

  constructor() {}

  sendMessage(message: Message): void {
    this.subject.next(message);
  }

  getMessage(): Observable<Message> {
    return this.subject.asObservable();
  }
}
